import React from "react";

const Header = () => {
  return (
    <div className='header'>
      <div className='container'>
        <div className='row v-center space-between'>
          <div className='logo'>
            <a href='/'>GLEDAM
            <br/>
            <span>Technologies</span>
            </a>
          </div>
          <div className='nav'>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
